import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import YijieLoginView from '@/views/YijieLoginView.vue';
import ChatListView from '@/views/ChatListView.vue';
import ChatDetailView from '@/views/ChatDetailView.vue';
import XiaodiLoginView from '@/views/XiaodiLoginView.vue';
import ZqsLoginView from '@/views/ZqsLoginView.vue';

// 定义路由
const routes = [
  { 
    path: '/login', 
    component: LoginView 
  },
  { 
    path: '/YijieLogin', 
    component: YijieLoginView 
  },
  { 
    path: '/XiaodiLogin', 
    component: XiaodiLoginView 
  },
  { 
    path: '/ZqsLogin', 
    component: ZqsLoginView 
  },
  { 
    path: '/list', 
    component: ChatListView,
    meta: { requiresAuth: true } // 需要登录才能访问
  },
  { 
    path: '/detail', 
    component: ChatDetailView,
    meta: { requiresAuth: true } // 需要登录才能访问
  },
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  // 如果用户已登录且试图访问登录页面，重定向到 /detail
  if (isAuthenticated && (to.path === '/login' || to.path === '/yijielogin' || to.path === '/Xiaodilogin' || to.path === '/ZqsLogin')) {
    next('/detail');
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果目标路由需要登录，并且用户未登录
    if (!isAuthenticated) {
      next('/login'); // 未登录用户直接跳转到登录页面
    } else {
      next(); // 用户已登录，允许访问
    }
  } else {
    next(); // 不需要登录的页面，允许访问
  }
});

export default router;